import * as React from "react"
import { Link } from 'gatsby'
import './footer.css'

const Footer = ({ values }) => (
    <footer className="footer">
        <div className="container">
            <div className="footer__line"></div>
            <div className="footer__left">
                <svg width="119" height="35" viewBox="0 0 119 35" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M35.3916 9.54688H44.4616L64.0273 34.3617H54.9573L35.3916 9.54688Z" fill="white" />
                    <path d="M111.03 0.617188H118.695V34.362H111.03V0.617188Z" fill="white" />
                    <path
                        d="M100.555 19.1355C100.597 16.6252 98.9643 14.4236 96.0512 14.4236C93.5719 14.4236 91.01 15.9874 90.8447 19.1355H100.555ZM107.291 30.082C104.832 33.2507 100.452 34.9997 96.3611 34.9997C89.9149 34.9997 82.9316 30.7404 82.9316 22.2425C82.9316 14.9586 88.0761 8.90918 96.3611 8.90918C103.53 8.90918 108.489 13.3948 108.468 21.9544V24.3618H90.8447C91.5265 27.4688 93.4273 29.1767 96.6297 29.1767C98.9437 29.1972 101.175 28.2713 102.807 26.6252L107.291 30.082Z"
                        fill="white" />
                    <path
                        d="M81.6304 15.2667H75.019L75.0603 24.6082C75.081 27.7564 75.4116 29.1556 78.2834 29.1556C79.3164 29.1556 80.928 28.9704 81.6304 28.5589V34.3613C80.0396 34.8552 77.9322 34.9992 76.3413 34.9992C70.577 34.9992 67.3332 32.5301 67.3332 27.5712V15.2667H63.7383L75.019 2.5918V9.54653H81.6511V15.2667H81.6304Z"
                        fill="white" />
                    <path
                        d="M38.2017 28.6213C37.8917 28.1892 37.6232 27.716 37.4166 27.2221L28.2432 4.95878C26.3837 2.53079 22.4376 3.08635 20.1855 7.46906C20.6401 8.5596 27.8713 26.1522 28.4085 27.4485C30.4746 32.3662 33.0158 34.9999 38.2843 34.9999C42.3131 34.9999 44.4205 33.1481 46.1767 31.1933C46.1973 31.1728 40.8669 32.3456 38.2017 28.6213Z"
                        fill="url(#paint0_linear)" />
                    <path
                        d="M28.2431 4.95885C28.2431 4.95885 26.1771 0 20.8053 0C16.5699 0 13.3881 1.87243 11.6526 6.06996L0 34.3621H2.56192C6.79736 34.3621 9.83448 32.5308 11.2187 29.1564C11.5906 28.251 18.7186 10.9671 19.7929 8.35391C19.9169 8.04526 20.0615 7.73662 20.2061 7.44856C22.4375 3.08642 26.3837 2.53086 28.2431 4.95885Z"
                        fill="#F47920" />
                    <path
                        d="M60.3082 9.54688C57.8909 9.54688 55.5356 9.97897 53.2836 12.4893L41.4451 25.802C40.1434 27.2629 39.193 28.1065 38.1807 28.6209C40.8666 32.3246 46.197 31.1724 46.197 31.1724L65.4114 9.54688H60.3082Z"
                        fill="url(#paint1_linear)" />
                    <defs>
                        <linearGradient id="paint0_linear" x1="39.1544" y1="33.9751" x2="24.9228" y2="-1.30622"
                            gradientUnits="userSpaceOnUse">
                            <stop stopColor="#F47920" />
                            <stop offset="0.3" stopColor="#F1771F" />
                            <stop offset="0.49" stopColor="#E9711D" />
                            <stop offset="0.64" stopColor="#DB6719" />
                            <stop offset="0.77" stopColor="#C75913" />
                            <stop offset="0.89" stopColor="#AD470C" />
                            <stop offset="1" stopColor="#8D3103" />
                        </linearGradient>
                        <linearGradient id="paint1_linear" x1="60.1604" y1="9.54175" x2="38.4875" y2="34.4816"
                            gradientUnits="userSpaceOnUse">
                            <stop stopColor="#F47920" />
                            <stop offset="0.3" stopColor="#F1771F" />
                            <stop offset="0.49" stopColor="#E9711D" />
                            <stop offset="0.64" stopColor="#DB6719" />
                            <stop offset="0.77" stopColor="#C75913" />
                            <stop offset="0.89" stopColor="#AD470C" />
                            <stop offset="1" stopColor="#8D3103" />
                        </linearGradient>
                    </defs>
                </svg>

                <ul>
                    <li><a href="https://www.linkedin.com/company/axtel-headsets/" target="_blank">
                        <svg width="26" height="26" viewBox="0 0 26 26" fill="none"
                            xmlns="http://www.w3.org/2000/svg">
                            <path
                                d="M24.9167 0H1.08333C0.4875 0 0 0.4875 0 1.08333V24.9167C0 25.5125 0.4875 26 1.08333 26H24.9167C25.5125 26 26 25.5125 26 24.9167V1.08333C26 0.4875 25.5125 0 24.9167 0ZM7.69167 22.1542H3.84583V9.75H7.69167V22.1542ZM5.79583 8.07083C4.55 8.07083 3.575 7.09583 3.575 5.85C3.575 4.60417 4.55 3.62917 5.79583 3.62917C7.04167 3.62917 8.01667 4.60417 8.01667 5.85C8.01667 7.04167 7.04167 8.07083 5.79583 8.07083ZM22.1542 22.1542H18.3083V16.1417C18.3083 14.6792 18.3083 12.8375 16.3042 12.8375C14.3 12.8375 13.975 14.4083 13.975 16.0333V22.1542H10.1292V9.75H13.8125V11.4292H13.8667C14.3542 10.4542 15.6542 9.425 17.4958 9.425C21.3958 9.425 22.1 11.9708 22.1 15.3292V22.1542H22.1542Z"
                                fill="#3F3F3F" />
                        </svg>

                    </a></li>
                    <li>
                        <a href="https://www.youtube.com/channel/UCk84Q17n7Ph6PQuvo5wcfOg" target="_blank">
                            <svg width="26" height="26" viewBox="0 0 26 26" fill="none"
                                xmlns="http://www.w3.org/2000/svg">
                                <path d="M11 15L16 13L11 11V15Z" fill="#3F3F3F" />
                                <path
                                    d="M13 0C5.8214 0 0 5.8214 0 13C0 20.1786 5.8214 26 13 26C20.1786 26 26 20.1786 26 13C26 5.8214 20.1786 0 13 0ZM21.123 13.0133C21.123 13.0133 21.123 15.6497 20.7886 16.9211C20.6011 17.6169 20.0524 18.1656 19.3566 18.3529C18.0853 18.6875 13 18.6875 13 18.6875C13 18.6875 7.92802 18.6875 6.64342 18.3396C5.94756 18.1523 5.39888 17.6034 5.21143 16.9076C4.87679 15.6497 4.87679 13 4.87679 13C4.87679 13 4.87679 10.3637 5.21143 9.09242C5.39868 8.39656 5.96085 7.8344 6.64342 7.64714C7.91473 7.3125 13 7.3125 13 7.3125C13 7.3125 18.0853 7.3125 19.3566 7.66043C20.0524 7.84769 20.6011 8.39656 20.7886 9.09242C21.1365 10.3637 21.123 13.0133 21.123 13.0133Z"
                                    fill="#3F3F3F" />
                            </svg>

                        </a>
                    </li>
                </ul>
            </div>
            <div className="footer__right">
                Copyright by Axtel 2021 - <Link to="https://axtelworld.uk/privacy-policy">Privacy policy</Link> | Design by g2team & alphta.de
            </div>
        </div>
    </footer>
)

export default Footer