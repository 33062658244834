import * as React from "react"
import { Link } from 'gatsby'
import './changer.css'

const Language = ({language}) => (
    <div class="language-changer">
        {language}
        <ul>
            <li>
                <Link to="https://axtelworld.uk">EN</Link>
            </li>
            <li>
                <Link to="https://axtelworld.pt">PT</Link>
            </li>
            <li>
                <Link to="https://axtelworld.es">ES</Link>
            </li>
            <li>
                <Link to="https://axtelworld.sk">SK</Link>
            </li>
            <li>
                <Link to="https://axtelworld.cz">CZ</Link>
            </li>
        </ul>
    </div>
)

export default Language
